<template>
  <div>
    <search @submit="submitSearch" />
    <div class="custom-table-operation-buttons">
      <a-button type="primary" @click="showNewModal" html-type="button">
        新增
      </a-button>
    </div>

    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="image" slot-scope="img, record">
        <img v-if="img" :src="img.url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="remark" slot-scope="text" class="custom-ellipsis custom-word-break-all">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showUpdateStatusModal(record)">修改状态</a>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
          <a @click="remove(record)" style="color: red">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增车辆模态框 -->
    <new
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑车辆模态框 -->
    <edit
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <!--  修改工作状态模态框  -->
    <change-status
      v-if="isShowUpdateWorkStatusModel"
      :visible.sync="isShowUpdateWorkStatusModel"
      :record="updatingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="Car"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Search from '@/views/cars/Search'
import PreviewImage from '@/components/PreviewImage'
import { deleteCar, findCars, updateCarEffective } from '@/api/car'

export default {
  name: 'CarList',
  components: {
    Pagination,
    PreviewImage,
    Search,
    New: () => import('@/views/cars/New'),
    Edit: () => import('@/views/cars/Edit'),
    ChangeStatus: () => import('@/views/cars/Status'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowUpdateWorkStatusModel: false, // 是否显示修改工作状态表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      updatingRecord: {}, // 正在修改工作状态记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImage: '',
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '车牌号',
          dataIndex: 'no',
          fixed: 'left',
          width: 120
        },
        {
          title: '图片',
          width: 80,
          dataIndex: 'image',
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '工作地点',
          width: 100,
          dataIndex: 'funeral_home_names'
        },
        {
          title: '工作状态',
          width: 90,
          dataIndex: 'status'
        },
        {
          title: '生效状态',
          width: 90,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '介绍',
          width: 150,
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '逝者姓名',
          width: 90,
          dataIndex: 'death_name'
        },
        {
          title: '逝者性别',
          width: 90,
          dataIndex: 'death_sex'
        },
        {
          title: '关联订单号',
          dataIndex: 'order_no',
          width: 220,
          ellipsis: true
        },
        {
          title: '操作',
          width: 240,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      if (record.image) {
        this.previewImage = record.image.url
      }
    },
    // 更新事件
    showNewModal() {
      this.isShowNewModal = true
    },

    // 编辑事件
    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    // 更新工作状态的事件
    showUpdateStatusModal(record) {
      this.updatingRecord = record
      this.isShowUpdateWorkStatusModel = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },
    remove(record) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？',
        content: '删除后无法恢复',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteCar(record.id).then(res => {
            thisForm.fetchData()
          })
        }
      })
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findCars(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    // 控制有效性的
    changeEffective(checked, record) {
      const vm = this
      const effective = checked
      this.$confirm({
        title: effective ? '确定将车辆生效吗?' : '确定失效车辆吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateCarEffective(record.id, { effective: effective }).then((res) => {
            vm.editingEffectiveId = 0
            if (res.code === 0) {
              record.effective = checked
            }
          }).catch(() => {
            vm.editingEffectiveId = 0
          })
        }
      })
    }
  }
}
</script>
