import request from '@/utils/request'

// 查询车辆列表
export function findCars(params) {
  return request({
    url: `/cars`,
    method: 'get',
    params: params
  })
}

// 更新车辆有效性
export function updateCarEffective(id, data) {
  return request({
    url: `/cars/${id}/effective`,
    method: 'patch',
    data
  })
}

// 更新车辆工作状态
export function updateCarStatus(id, data) {
  return request({
    url: `/cars/${id}/status`,
    method: 'patch',
    data
  })
}

// 更新车辆
export function updateCar(id, data) {
  return request({
    url: `/cars/${id}`,
    method: 'put',
    data
  })
}

// 创建车辆
export function createCar(data) {
  return request({
    url: `/cars`,
    method: 'post',
    data
  })
}

// 获取车辆下拉选项
export function findCarOptions(params) {
  return request({
    url: `/cars/options`,
    method: 'get',
    params: params
  })
}

// 删除车辆
export function deleteCar(id) {
  return request({
    url: `cars/${id}`,
    method: 'delete'
  })
}
